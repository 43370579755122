import React, { useEffect, useState } from 'react';
import '../../node_modules/odometer/themes/odometer-theme-car.css';
import Odometer from 'react-odometerjs';
import to_the_moon from "assets/images/main/presale/tothemoon.png";
import Skeleton from 'react-loading-skeleton';
import { BASE_COLOR, HIGHLIGHT_COLOR } from 'utils/constants';

const TokenMeter = (props) => {
    const { presSaleStats } = props;
    const totalPresalePhaseToken = presSaleStats.tokensAllocated;
    const tokenSoldTillNow = presSaleStats.tokensSold;
    const [value, setValue] = useState(tokenSoldTillNow);

    useEffect(() => {
        const timeoutId = setTimeout(() => setValue(tokenSoldTillNow), 2000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [tokenSoldTillNow]);

    return (
        <div>
            <div className="text-center text-color-light font-[HelveticaNeueLight] font-normal text-white text-[18px]">
                <nobr className="flex items-center justify-center gap-1">
                    <span className=" text-color-light font-[archivo] text-[24px] font-medium text-white">To the moon</span>
                    <img src={to_the_moon} className="imgDisplayInline"></img>&nbsp;&nbsp;&nbsp;
                    <span className=" font-[archivo] text-[24px] font-medium text-white">{tokenSoldTillNow ? parseFloat(tokenSoldTillNow).toFixed(3) : (
                        <Skeleton
                            width={50}
                            height={24}
                            baseColor={BASE_COLOR}
                            highlightColor={HIGHLIGHT_COLOR}
                        />
                    )}</span>
                    <span className="text-color-light font-[archivo] text-[24px] font-medium text-white"> / </span>
                    <span className="text-color-light font-[archivo] text-[24px] font-medium text-white">{totalPresalePhaseToken ? totalPresalePhaseToken : <Skeleton width={50} height={24} baseColor={BASE_COLOR} highlightColor={HIGHLIGHT_COLOR} />}</span>
                </nobr>
            </div>
            {value && <div className="text-center"><Odometer value={value} format="d" /></div>}

        </div>
    )
}

export default TokenMeter;