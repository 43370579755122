import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactGAImplementation } from "react-ga4";
import ReactGA from 'react-ga4';
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
const BlogTemplate = () => {
    // google analytic code
    // ReactGA.send({
    //     hitType: "pageview",
    //     page: "/BlogPage5",
    //     title: "BlogPage5",
    // });

    const [blog, setBlog] = useState<any>(null)
    const [latestBlogs, setLatestBlogs] = useState<any>(null)
    const [date, setDate] = useState<string>("")
    const [tags, setTags] = useState<any>(null)
    const navigate = useNavigate();
    const { blogId } = useParams()
    // console.log(blogId);


    // const isTablet = useMediaQuery({ maxWidth: 900 });
    // const navigate = useNavigate();
    const getBlogsFromDatabase = async () => {
        try {


            // const blog = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/` + blogId)
            const blog = await axios.get(`${process.env.REACT_APP_WORDPRESS_API_URL}/posts/slug:` + blogId)

            console.log(blog.data);
            const date = new Date(blog.data.modified)
            const formattedDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });

            setBlog(blog.data)
            setDate(formattedDate)
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        getBlogsFromDatabase()
    }, [])

    const getLatestBlogs = async () => {
        try {


            // const blog = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/` + blogId)
            const latestBlogs = await axios.get(`${process.env.REACT_APP_WORDPRESS_API_URL}/posts?number=4&order_by=modified&exclude=` + blog.ID)

            // console.log(blog);
            // const date = new Date(blog.data.modified)
            // const formattedDate = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });

            setLatestBlogs(latestBlogs.data.posts)
            // setDate(formattedDate)
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        if (blog !== null) {
            getLatestBlogs()
        }
    }, [blog])

    return (
        <div className="bg-[black] px-6 md:px-10 lg:px-28 flex justify-center">
            <div className="max-w-[800px] w-full flex flex-col gap-6 pt-[30px]">
                {blog !== null && <>
                    <p className="font-normal text-[16px] font-[archivo] tracking-wide text-color-semilight">
                        {date}
                    </p>
                    <div className="text-[32px] lg:text-[48px] font-semibold">
                        {blog.title}
                    </div>

                    {/* <img src={blog.image_url} className="w-full max-h-[30rem]" /> */}
                    <div dangerouslySetInnerHTML={{ __html: blog.content }} className="blogContent"></div>
                    <div className="flex">

                        {blog.tags !== null ?
                            Object.entries(blog.tags).map(([key, value], i) => {
                                // console.log(key, value);

                                return <p key={i} className="font-normal text-[12px] font-[archivo] tracking-wide text-color-semilight mr-[2rem] p-2 border-2 rounded-md">
                                    {key}
                                </p>
                            })
                            : null}

                    </div>
                </>

                }
                <div className="w-full">
                    <h3 className="font-semibold py-10 font-[archivo] text-[24px] leading-[32px]">
                        Read More Blogs
                    </h3>
                    <div className="grid grid-cols-1 xs:grid-cols-1 md:grid-cols-2 lg2:grid-cols-4 gap-4 rounded-xl overflow-hidden pb-24">
                        {latestBlogs?.map((blog, index) => (
                            <div key={index} className="cursor-pointer bg-white/10 rounded-xl" onClick={() => {
                                navigate(`/community/blog/` + blog.slug)
                                window.location.reload() // Have to add this cause navigate alone is not loading the page
                            }}>
                                <img src={blog.featured_image} alt="" className="w-full rounded-t-xl" />
                                <div className="p-6">
                                    <h4 className="text-[24px] font-normal mb-2 leading-[32px] line-clamp-3">
                                        {blog.title}
                                    </h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default BlogTemplate;

/**
 * Lore
 */

